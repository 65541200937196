/* You can add global styles to this file, and also import other style files */
@import url("assets/css/typography.css");
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  max-width: 60.7vh;
}

.st_bred {
  min-width:485px;
 /*  background-color: greenyellow; */
}
.PeTec_gron {
  color: #00A885;
}

.PeTec_rod {
  color: #8b0000;
}
header {
  background: width;
  padding: 0em;
}
div.center {
  text-align: center;
}

#main {
  display: flex;
}

#main>content {
  flex: 1;
}

#main>nav {
  order: -1;
}

#main>nav a {
  color: #00A885;
  font-weight: bold;
}

#main>nav ul {
  padding: 0;
  margin: 10px 0 0 50px;
  list-style: none;
}

#main>nav a:hover {
  color: black;
}

content {
  padding: 1em;
  max-width: 62vh;
  font-size: 110%;
  font-family: verdana, arial, helvetica, sans-serif;
}

footer {
  height: 6.5vh;
}

.normalDrift {
  display: normal;
  color: #00A885;
}

.unormalDrift {
  display: none;
}



ul li .vMenuUNormalDrift {
  /* color: red!important; */
  color:  #00A885;
}
